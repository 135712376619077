<template>
  <header class="page-header">
    <div
      id="results-page-heading"
      ref="heading"
      class="row no-gutters page-header__heading"
    >
      <div class="col mb-6" :class="fullWidth ? 'col-md-12' : 'col-md-8'">
        <p v-if="$slots.pretitle" class="text-grey-500">
          <small>
            <slot name="pretitle" />
          </small>
        </p>
        <h2 v-if="$slots.title" class="page-header__title">
          <slot name="title" />
        </h2>
        <p v-if="$slots.subtitle" class="text-gray-900 mt-2 mr-10">
          <small>
            <slot name="subtitle" />
          </small>
        </p>
        <div v-if="$slots.ctas" class="text-gray-900 mt-6 mr-10">
          <slot name="ctas" />
        </div>
      </div>
      <div
        v-if="$slots.navigation"
        id="page-header-navigation"
        ref="navigation"
        class="col-12 col-md-auto page-header__navigation"
      >
        <slot name="navigation" />
      </div>
    </div>
  </header>
</template>

<script lang="ts">
export default {
  props: {
    fullWidth: {
      type: Boolean,
      required: false,
      default: false
    }
  }
}
</script>

<style lang="scss" scoped>
.page-header {
  margin-left: auto;
  margin-right: auto;
  width: 100%;

  // TODO: Do not use html elements as selectors
  p small {
    font-size: rem(16);
  }

  @include viewport('md') {
    margin-bottom: rem(8);
  }

  @include viewport('md', 'max-width') {
    margin-bottom: rem(24);
  }
}

.page-header__heading {
  align-items: end;
  justify-content: space-between;

  .page-header--reverse & {
    align-items: flex-end;
  }
}

.page-header__title {
  padding-right: 10px;
  min-height: rem(40);
}

.page-header__navigation {
  @include viewport('md') {
    margin-bottom: rem(24);
  }

  .scrollable-badge-selector {
    vertical-align: bottom;
  }
}
</style>
